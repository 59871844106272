<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            title="Festas Religiosas"
            titleColor="#000"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                A região conhecida como Mata do Chaves localiza-se entre
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_aguas_claras' }"
                  >Águas Claras</router-link
                >
                e
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_paracatu_de_cima' }"
                  >Paracatu de Cima</router-link
                >, com acesso pela estrada que liga essas duas comunidades.
                Trata-se de área rural com relevo montanhoso coberto por
                extensões de mata e áreas de pastagem. As terras são
                beneficiadas por nascentes e outros corpos hídricos, com solo
                úmido e favorável ao plantio.
              </p>
              <p>
                Os terrenos são destinados aos cultivos agrícolas como roçados,
                hortas e pomares, além da criação de animais, com ênfase para
                bovinos leiteiros. A produção era voltada para o autoconsumo,
                bem como para comercialização.
              </p>
              <p>
                Além do trabalho com lavoura e criação de animais, na comunidade
                era comum o preparo de quitutes e comidas típicas mineiras como
                feijão tropeiro. As receitas e maneiras de preparar
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudo_soberania_quintandas' }"
                  >quitandas</router-link
                >
                foram sendo passadas de geração a geração, sendo
                tradicionalmente preparadas pelas famílias rosquinhas, biscoito
                de polvilho, bolo, broa de fubá. Também aprendiam com os mais
                velhos a plantar
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                  >hortaliças, frutíferas e roças</router-link
                >.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_matachaves_01.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Paisagem e relevo típicos da região de Mata do Chaves. Foto: Acervo
            de vistoria técnica realizada pela Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_matachaves_02.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Cultivo de hortaliças em Mata do Chaves. Foto: Acervo de vistoria
            técnica realizada pela Cáritas, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Os terrenos da região são de grandes extensões, com as maiores
                áreas destinadas aos sistemas produtivos. Geralmente próximas a
                uma nascente ou córrego, são construídas as edificações, tanto a
                residência quanto as acessórias de uso rural.
              </p>
              <p>
                Entre as manifestações tradicionais estão a Festa de São João,
                as festas juninas, assim como as celebrações religiosas. Mata do
                Chaves tem como padroeira Nossa Senhora do Carmo, assim como a
                sede de Mariana.
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
                  >As festividades em louvor à padroeira</router-link
                >
                ocorrem de 13 a 16 de julho, quando ocorre a carreata e a missa,
                realizada na Capela do Chaves. A comunidade está vinculada à
                Paróquia de São Caetano, no distrito de Monsenhor Horta.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_matachaves_03.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Horta protegida por tela para o cultivo familiar. Foto: Acervo de
            vistoria técnica realizada pela Cáritas, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Com o rompimento da barragem de Fundão, a região ficou
                prejudicada inicialmente pela perda de acesso, já que as pontes
                e estradas foram destruídas. Mesmo tendo sido retomado o acesso,
                a comunidade, assim como várias da região, encontra dificuldades
                em continuar suas atividades produtivas, já que enfrenta evasão
                populacional, não encontrando mão de obra para trabalhar, bem
                como compradores para escoar a produção. Os produtores locais
                foram perdendo suas produções e interrompendo as atividades.
              </p>
              <p>
                A destruição do entorno pela lama causou prejuízos materiais e
                danos emocionais, já que de Mata do Chaves ouvia-se os estrondos
                que a devastação da lama causou por onde passou. As famílias
                convivem ainda com a preocupação referente à situação
                financeira, já que não podem mais plantar e comercializar a
                produção. O consumo próprio também ficou prejudicado, sendo que
                anteriormente os alimentos eram produzidos com abundância,
                sempre disponíveis de acordo com a estação do ano. Soma-se a
                isso o fato de os corpos hídricos dos terrenos da região
                encontram-se turvas, gerando insegurança quanto à presença de
                material tóxico tanto na água quanto no solo.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O convívio social também ficou abalado, a exemplo da Festa de
                São João, que não recebe mais a participação de visitantes,
                tanto pelo receio de um novo rompimento, quanto pela mudança de
                pessoas das comunidades próximas como Paracatu de Cima,
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_paracatu_de_baixo' }"
                  >Paracatu de Baixo</router-link
                >
                e
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_ponte_do_gama' }"
                  >Ponte do Gama</router-link
                >
                para outros locais. Outra manifestação frequentada pelos
                moradores de Mata do Chaves é a Festa de São Luís no distrito de
                Águas Claras, que também não acontece da mesma forma, havendo
                poucos participantes. Da mesma forma, com a devastação das
                comunidades vizinhas e a mudança forçada da população, as festas
                que anteriormente ocorriam em Paracatu de Cima, Paracatu de
                Baixo e Ponte do Gama não ocorrem mais ou acontecem de maneira
                adaptada.
              </p>
              <p>
                Após o rompimento, foram sendo abandonados os modos de fazer, a
                exemplo de quitandas que eram comercializadas em Mariana. As
                famílias tiveram perda de rendimentos e não conseguem mais
                retomar as atividades pois despenderiam de muitos recursos
                financeiros. Foi perdido o convívio social, após terem passado
                também longo tempo de isolamento, sem encontrar amigos e
                familiares, sem poder frequentar festas e cultos.
              </p>
              <p style="text-align: center">
                <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_matachaves_04.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista do conjunto de edificações situadas em terreno da região de
            Mata do Chaves, à esquerda da imagem, observa-se a residência e
            próximo a elas, duas edificações relacionadas aos sistemas
            produtivos. Foto: Acervo de vistoria técnica realizada pela Cáritas,
            2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="table__section">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

#app
  > div
  > main
  > div
  > div
  > div.container
  > section
  > div
  > div
  > div.v-data-table.theme--light
  > div
  > table
  > thead
  > tr
  > th {
  color: black;
  font-size: 0.6em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
